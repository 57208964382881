import { Box, makeStyles, Theme, Tooltip } from "@material-ui/core";
import {
  Announcement,
  Home,
  LiveHelp,
  MonetizationOn,
  PanTool,
  PauseCircleFilled,
} from "@material-ui/icons";
import { VIPIcon } from "@yardzen-inc/react-common";
import React, { FC } from "react";
import { PostPLPHoldsType } from "./types";

export interface PostPLPHoldListItemIconsProps {
  holdStatuses: PostPLPHoldsType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  green: {
    color: "#00cc00",
  },
  blue: {
    color: "cornflowerblue",
  },
  gray: {
    color: "gray",
  },
  orange: {
    color: "orange",
  },
  manual: {
    color: "#C05757",
  },
}));

const PostPLPHoldListItemIcons: FC<PostPLPHoldListItemIconsProps> = ({
  holdStatuses,
}) => {
  const classes = useStyles();

  const HOLD_STATUS_MAP = {
    VIP: (
      <Tooltip title="Client is a VIP" key="vip-icon">
        <div>
          <VIPIcon size={40} />
        </div>
      </Tooltip>
    ),
    ANNOTATED: (
      <Tooltip title="Client left annotations or PM responses" key="anno-icon">
        <Announcement fontSize="large" color="primary" />
      </Tooltip>
    ),
    BUDGET_WARNING: (
      <Tooltip title="Client received a budget warning" key="budget-icon">
        <MonetizationOn fontSize="large" classes={{ root: classes.green }} />
      </Tooltip>
    ),
    PM_QUESTIONS: (
      <Tooltip title="Client received PM questions" key="question-icon">
        <LiveHelp fontSize="large" classes={{ root: classes.blue }} />
      </Tooltip>
    ),
    RECENTLY_DELIVERED: (
      <Tooltip title="Awaiting client response or 48 hours" key="paused-icon">
        <PauseCircleFilled fontSize="large" classes={{ root: classes.gray }} />
      </Tooltip>
    ),
    EXTERIOR_DESIGN: (
      <Tooltip
        title="Client purchased an exterior design package"
        key="exterior-icon"
      >
        <Home fontSize="large" classes={{ root: classes.orange }} />
      </Tooltip>
    ),
    MANUAL_HOLD: (
      <Tooltip title="Manual hold has been placed" key="manual-icon">
        <PanTool fontSize="large" classes={{ root: classes.manual }} />
      </Tooltip>
    ),
  };

  return renderIcons();

  function renderIcons() {
    const icons = [];

    if (holdStatuses.includes("VIP")) {
      icons.push(HOLD_STATUS_MAP.VIP);
    }

    if (holdStatuses.includes("ANNOTATED")) {
      icons.push(HOLD_STATUS_MAP.ANNOTATED);
    }

    if (holdStatuses.includes("BUDGET_WARNING")) {
      icons.push(HOLD_STATUS_MAP.BUDGET_WARNING);
    }

    if (holdStatuses.includes("PM_QUESTIONS")) {
      icons.push(HOLD_STATUS_MAP.PM_QUESTIONS);
    }

    if (holdStatuses.includes("RECENTLY_DELIVERED")) {
      icons.push(HOLD_STATUS_MAP.RECENTLY_DELIVERED);
    }

    if (holdStatuses.includes("EXTERIOR_DESIGN")) {
      icons.push(HOLD_STATUS_MAP.EXTERIOR_DESIGN);
    }

    if (holdStatuses.includes("MANUAL_HOLD")) {
      icons.push(HOLD_STATUS_MAP.MANUAL_HOLD);
    }

    return (
      <Box display="flex" alignItems="center" width="180px">
        {icons}
      </Box>
    );
  }
};

export { PostPLPHoldListItemIcons };
export default PostPLPHoldListItemIcons;
