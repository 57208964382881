import { Box, makeStyles, Theme } from "@material-ui/core";
import { ImportantDevices } from "@material-ui/icons";
import React, { FC } from "react";

export interface InfluencerIconProps {
  size: number;
  onClick?: () => void;
}

export interface InfluencerIconStyleProps {
  pixelSize: string;
  textSize: string;
  onClickBoolean: boolean;
}

const useStyles = makeStyles<Theme, InfluencerIconStyleProps>(
  (theme: Theme) => ({
    root: {
      height: (props) => props.pixelSize,
      width: (props) => props.pixelSize,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: (props) => (props.onClickBoolean ? "pointer" : undefined),
    },
    textBox: {
      position: "absolute",
      display: "inline",
      fontSize: (props) => props.textSize,
    },
  })
);

const InfluencerIcon: FC<InfluencerIconProps> = ({ size, onClick }) => {
  const pixelSize = `${size}px`;
  const textSize = `${size / 45}rem`;
  const classes = useStyles({
    pixelSize,
    textSize,
    onClickBoolean: !!onClick,
  });

  return (
    <Box className={classes.root} onClick={onClick}>
      <ImportantDevices
        style={{
          height: pixelSize,
          width: pixelSize,
          color: "maroon",
        }}
      />
    </Box>
  );
};

export { InfluencerIcon };
export default InfluencerIcon;
