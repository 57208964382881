import { Theme, withStyles } from "@material-ui/core";
import { Profile, Project, Revision } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import * as React from "react";
import LoadingAbsCenter from "../../Components/LoadingAbsCenter";
import Designs from "../Designs";
import DesignAssignmentControls from "./DesignAssignment/DesignAssignmentControls";
import QAMerge from "./QAMerge";
import RevisionList from "./RevisionList";

export interface Props {
  classes: any;
  clientRecord: Profile;
  isEmployee: boolean;
  fetchProfile: CallableFunction;
}

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
      alignItems: "center",
    },
    maxWidth: "calc(100vw - 13rem)",
  },
  stepList: {
    width: "220px",
  },
  stepContent: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "100vw",
    width: "100%",
    height: "100%",
  },
});

function RevisionContainer(props: Props) {
  const { classes } = props;
  const [loading, setLoading] = React.useState(true);
  const [selectedRevision, setSelectedRevision] =
    React.useState<Revision | null>(null);
  const [revisions, setRevisions] = React.useState<Revision[]>([]);
  const [project, setProject] = React.useState<Project | null>(null);

  const conceptualRevisionId = props.clientRecord.conceptualRevision;
  const finalRevisionId = props.clientRecord.finalRevision;
  const v3RevisionId = props.clientRecord.v3Revision;

  React.useEffect(listenToRevisions, [props.clientRecord.id]);
  React.useEffect(setInitialRevision, [loading]);
  React.useEffect(listenForProject, [props.clientRecord.id]);

  // Quick and dirty update of selected revision to pass to childrenm
  React.useEffect(() => {
    if (revisions && revisions.length) {
      const newSelected =
        revisions.find((revision) => {
          return revision.id === selectedRevision?.id;
        }) || revisions[revisions.length - 1];

      if (newSelected) {
        setSelectedRevision(newSelected);
      }
    }
  }, [revisions]);

  if (
    props.clientRecord.featureFlags &&
    props.clientRecord.featureFlags.includes("newDesignsTab")
  ) {
    return <Designs clientRecord={props.clientRecord} />;
  }

  function renderView() {
    if (selectedRevision === null)
      return (
        <>
          {project && (
            <DesignAssignmentControls
              clientRecord={props.clientRecord}
              project={project}
              selectedRevision={selectedRevision}
              revisions={revisions}
            />
          )}
        </>
      );

    return (
      <QAMerge
        finalRevisionId={finalRevisionId ?? void 0}
        conceptualRevisionId={conceptualRevisionId ?? void 0}
        v3RevisionId={v3RevisionId ?? void 0}
        clientRecord={props.clientRecord}
        revisions={revisions}
        onDelete={() => {}}
        revision={selectedRevision}
        project={project}
      />
    );
  }

  if (loading) return <LoadingAbsCenter in={loading} />;

  return (
    <div className={classes.root}>
      <div className={classes.stepList}>
        <RevisionList
          finalRevisionId={finalRevisionId ?? void 0}
          conceptualRevisionId={conceptualRevisionId ?? void 0}
          v3RevisionId={v3RevisionId ?? void 0}
          projectState={props.clientRecord.projectState as any}
          profileId={props.clientRecord.id}
          selectedRevision={selectedRevision as Revision}
          handleSelectRevision={handleSelectRevision}
          activeRevision={revisions[revisions.length - 1]}
          revisionList={revisions}
          isEmployee={props.isEmployee}
          project={project}
        />
      </div>
      {revisions != undefined ? renderView() : null}
    </div>
  );

  function handleSelectRevision(revision: Revision | null) {
    setSelectedRevision(revision);
  }

  function listenToRevisions(): () => void {
    const {
      clientRecord: { id },
    } = props;
    return firebase
      .firestore()
      .collection("revisions")
      .where("profileId", "==", id)
      .orderBy("dateCreated", "asc")
      .onSnapshot((snap) => {
        if (snap.empty) {
          setLoading(false);
          return setRevisions([]);
        }
        const revs = snap.docs.map((d) => Revision.initializefromDoc(d));

        setRevisions(revs);
        setLoading(false);
      });
  }

  function listenForProject(): () => void {
    // TODO: change this 5 months from now when yz customers have multiple projects
    return firebase
      .firestore()
      .collection("projects")
      .where("profileId", "==", props.clientRecord.id) // Actually profile id, thanks will...
      .onSnapshot(async (snap) => {
        if (snap.empty) {
          throw new Error("project doesnt exist...");
        }

        const props = snap.docs[0].data();

        if (!props) {
          throw new Error("project document has no data...");
        }

        const proj = new Project();
        proj.setProperties({ id: snap.docs[0].id, ...props } as any);

        setProject(proj);
      });
  }

  function setInitialRevision(): void {
    if (loading || !revisions.length) return;

    const params = new URLSearchParams(window.location.search);

    do {
      if (!params.has("ir")) {
        break;
      }

      const initial = params.get("ir");

      if (!initial) {
        break;
      }

      const selected = revisions.find((r) => r.id === initial);

      if (!selected) {
        break;
      }

      return setSelectedRevision(selected);
    } while (false);

    return setSelectedRevision(revisions[revisions.length - 1]);
  }
}

export default withStyles(styles)(RevisionContainer);
