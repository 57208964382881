import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { Media, MediaVisibility, Profile, Project } from "@yardzen-inc/models";
import { ProjectFunctions } from "@yardzen-inc/util";
import firebase from "firebase/compat/app";
import * as React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AnnotatorBeta from "./ClientDetail/AnnotatorBeta";
import Assignments from "./ClientDetail/Assignments";
import { CdmClientDetailPage } from "./ClientDetail/CdmTab.tsx";
import { ClientDetailHeaderWithTabs } from "./ClientDetail/ClientDetailHeaderWithTabs";
import DesignBriefClientPage from "./ClientDetail/DesignBriefPage.tsx/DesignBriefClientDetailPage";
import ClientDetailTab from "./ClientDetail/Detail/ClientDetailTab";
import ManageProjectTagsModal from "./ClientDetail/Detail/ManageProjectTagsModal";
import ManualHoldToggleModal from "./ClientDetail/Detail/ManualHoldToggleModal";
import Elements from "./ClientDetail/Elements";
import ExteriorDesignRouterTab from "./ClientDetail/ExteriorDesign/ExteriorDesignRouterTab";
import ClientFeedbackTab from "./ClientDetail/Feedback/ClientFeedbackTab";
import MediaView from "./ClientDetail/Media";
import OnboardPage from "./ClientDetail/Onboarding/OnboardPage";
import RevisionAPIWrapper from "./ClientDetail/Revisions/RevisionAPIWrapper";
import TypeformDetail from "./ClientDetail/TypeformDetail";
import {
  setUserDisabledApi,
  setUserEnabledApi,
} from "./ClientDetail/userDisabledStatusApiCalls";
import BudgetInformationDisplayTab from "./Components/Budget/BudgetInformationDisplayTab";
import { useDispatch, useSelector } from "./state/hooks";
import { setProfile as setProfileInRedux } from "./state/slices/entities/profilesSlice";
import {
  fetchProjectByProfileId,
  selectProjectByProfileId,
  setProject as setProjectInRedux,
} from "./state/slices/entities/projectsSlice";

export interface Props {
  history: any;
  location: any;
  match: any;
}

export interface State {
  showSidebar: boolean;
  isLoggedIn: boolean | null;
  isEmployee: boolean;
  isLoading: boolean;
  permissions: object;
  isLoadingMedia: boolean;
  isLoadingProfile: boolean;
  mediaRecords: Array<Media>;
  error: boolean;
  clientRecord: Profile;
  activeItem: string;
  selectedMediaItem: number;
  fileUploadVisibility: MediaVisibility;
  deleteInProgress: boolean;
  designProfile: object;
  clientProject: object;
  tagManagerOpen: boolean;
  manualHoldModalOpen: boolean;
  isVIP: boolean;
  holdStatuses: string[];
  isUserDisabled: boolean;
}

function ClientDetail(props: Props) {
  const PERMISSIONS = {
    admin: {
      media: {
        access: "update",
        visibility: {
          /* all the thing */
        },
      },
      profile: {
        access: "update",
        fields: {},
      },
    },
    designer: {
      media: {
        access: "readonly",
        visibility: {
          isDesignerVisible: true,
        },
      },
      profile: {
        access: "readonly",
        fields: {
          upworkName: "hidden",
          delighted: "hidden",
          conciergeHours: "hidden",
          legacyStatus: "hidden",
        },
      },
    },
  };

  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean | null>(null);
  const [isEmployee, setIsEmployee] = React.useState(false);
  const [permissions, setPermissions] = React.useState<any>(
    PERMISSIONS["designer"]
  );
  const [isLoadingProfile, setIsLoadingProfile] = React.useState(true);
  const [clientRecord, setClientRecord] = React.useState<Profile>({
    id: "",
    userId: "",
    firstName: "",
    projectManager: "",
  } as any);
  const [fileUploadVisibility, setFileUploadVisibility] = React.useState({});
  const [designProfile, setDesignProfile] = React.useState({});
  const [tagManagerOpen, setTagManagerOpen] = React.useState(false);
  const [manualHoldModalOpen, setManualHoldModalOpen] = React.useState(false);
  const [isVIP, setIsVIP] = React.useState(false);
  const [isInfluencer, setIsInfluencer] = React.useState(false);
  const [holdStatuses, setHoldStatuses] = React.useState<string[]>([]);
  const [isUserDisabled, setIsUserDisabled] = React.useState(false);
  const [project, setProject] = React.useState<Project>({} as any);

  const projectSelectedFromRedux = useSelector(
    // currently this is only for debugging.
    selectProjectByProfileId(clientRecord?.id)
  );

  if (projectSelectedFromRedux) {
    console.log(
      "In ClientDetail. Successfully selected project from redux:",
      projectSelectedFromRedux
    );
  }

  React.useEffect(() => {
    const profileId = clientRecord?.id;
    if (!profileId) return;

    console.log(
      `In ClientDetail/useEffect. Dispatching fetchProjectByProfileId with profileId: ${profileId}`
    );
    dispatch(fetchProjectByProfileId(profileId));
  }, [clientRecord]);

  React.useEffect(fetchUser, []);

  React.useEffect(() => {
    fetchAndSetUserDisabledStatus(clientRecord.email);
  }, [clientRecord.email]);

  if (isLoggedIn === false) {
    return (
      <div>
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      </div>
    );
  }

  return isLoadingProfile || !project ? (
    <Typography component={"span"}>
      <CircularProgress /> Loading profile...
    </Typography>
  ) : (
    <div>
      <ClientDetailHeaderWithTabs
        clientRecord={clientRecord as Profile}
        clientProject={project}
        setManualHoldModalOpen={setManualHoldModalOpen}
        setTagManagerOpen={setTagManagerOpen}
        isVIP={isVIP}
        isInfluencer={isInfluencer}
        isEmployee={isEmployee}
        holdStatuses={holdStatuses}
        isUserDisabled={isUserDisabled}
        refreshHoldStatus={() => fetchPostPLPHold(project?.profileId)}
      />
      <Paper>
        <div style={{ padding: 10 }}>
          <Switch>
            <Route
              exact
              path={`${props.match.path}`}
              render={() => (
                <ClientDetailTab
                  clientRecord={clientRecord}
                  clientProject={project as Project}
                  fetchProfile={fetchProfile}
                  permissions={permissions}
                  designProfile={designProfile}
                  history={props.history}
                  handleSetClientRecord={handleSetClientRecord}
                  isEmployee={isEmployee}
                  isUserDisabled={isUserDisabled}
                  handleDisabledStatusChange={handleDisabledStatusChange}
                />
              )}
            />
            <Route
              path={`${props.match.path}/onboard`}
              render={(routeProps) => (
                <OnboardPage {...routeProps} clientRecord={clientRecord} />
              )}
            />
            <Route
              path={`${props.match.path}/budget-checklist`}
              render={(routeProps) => (
                <BudgetInformationDisplayTab
                  {...routeProps}
                  userId={clientRecord.id}
                  packageType={clientRecord.package}
                />
              )}
            />
            <Route path={`${props.match.path}/exterior-design`}>
              <ExteriorDesignRouterTab clientRecord={clientRecord} />
            </Route>
            <Route
              exact
              path={`${props.match.path}/assignments`}
              render={() => {
                return <Assignments clientRecord={clientRecord} {...props} />;
              }}
            />
            <Route
              path={`${props.match.path}/detail`}
              render={() => (
                <ClientDetailTab
                  isUserDisabled={isUserDisabled}
                  clientRecord={clientRecord}
                  clientProject={project as Project}
                  fetchProfile={fetchProfile}
                  permissions={permissions}
                  designProfile={designProfile}
                  history={props.history}
                  handleSetClientRecord={handleSetClientRecord}
                  isEmployee={isEmployee}
                  handleDisabledStatusChange={handleDisabledStatusChange}
                />
              )}
            />
            <Route
              path={`${props.match.path}/feedback`}
              render={() => (
                <ClientFeedbackTab
                  clientRecord={clientRecord}
                  permissions={permissions}
                />
              )}
            />
            <Route
              path={`${props.match.path}/design-brief`}
              render={() => {
                return <DesignBriefClientPage profile={clientRecord} />;
              }}
            />
            {isEmployee ? (
              <Route
                path={`${props.match.path}/cdm`}
                render={(props) => (
                  <CdmClientDetailPage profile={clientRecord} {...props} />
                )}
              />
            ) : null}
            <Route
              path={`${props.match.path}/typeform`}
              render={typeformDetail}
            />
            <Route
              path={`${props.match.path}/elements`}
              render={(routeProps) => (
                <Elements {...routeProps} clientRecord={clientRecord} />
              )}
            />
            <Route
              path={`${props.match.path}/media`}
              render={(routeProps) => (
                <MediaView
                  {...routeProps}
                  isEmployee={isEmployee}
                  fetchUser={fetchUser}
                  permissions={permissions}
                  fileVisibility={fileUploadVisibility}
                  clientRecord={clientRecord}
                />
              )}
            />
            <Route
              path={`${props.match.path}/deliverables`}
              render={() => (
                <RevisionAPIWrapper
                  isEmployee={isEmployee}
                  clientRecord={clientRecord}
                  fetchProfile={fetchProfile}
                />
              )}
            />
            {isEmployee ? (
              <Route
                path={`${props.match.path}/revision-feedback`}
                render={() => <AnnotatorBeta clientRecord={clientRecord} />}
              />
            ) : null}
          </Switch>
        </div>
      </Paper>
      <ManageProjectTagsModal
        open={tagManagerOpen}
        onClose={() => {
          fetchPostPLPHold(project?.profileId);
          setTagManagerOpen(false);
        }}
        clientProject={project as Project}
      />
      <ManualHoldToggleModal
        open={manualHoldModalOpen}
        onClose={() => setManualHoldModalOpen(false)}
        project={project as Project}
        holdStatuses={holdStatuses}
        refreshHolds={fetchPostPLPHold}
      />
    </div>
  );

  function typeformDetail() {
    return (
      <TypeformDetail profile={clientRecord} profileId={clientRecord["id"]} />
    );
  }

  function handleSetClientRecord(clientRecord: any) {
    setClientRecord(clientRecord);
  }

  function fetchUser() {
    // @ts-ignore
    const user = props["user"];
    if (!user || !user.email) {
      return setIsLoggedIn(false);
    }

    if (user.email.indexOf("@yardzen.co") === -1) {
      setIsLoggedIn(true);
      setIsEmployee(false);
      setPermissions(PERMISSIONS["designer"]);
      setFileUploadVisibility({
        isClientVisible: false,
        isDesignerVisible: true,
      });
    } else {
      setIsLoggedIn(true);
      setIsEmployee(true);
      setPermissions(PERMISSIONS["admin"]);
      setFileUploadVisibility({
        isClientVisible: false,
        isDesignerVisible: false,
      });
    }

    const { userId } = props.match.params;
    subscribeToProfile(userId);
    subscribeToProject(userId);
    fetchPostPLPHold(userId);
  }

  async function fetchAndSetUserDisabledStatus(email: string | undefined) {
    const getUserByEmail = firebase.functions().httpsCallable("getUserByEmail");
    const userRecord = await getUserByEmail(email);
    setIsUserDisabled(userRecord?.data?.disabled ?? false);
  }

  function subscribeToProfile(profileId: string) {
    const docRef = firebase.firestore().collection("profiles").doc(profileId);

    docRef.onSnapshot((snap) => {
      const data = snap.data();
      if (!data) return;
      data.id = snap.id;

      setClientRecord(data as Profile);
      setIsLoadingProfile(false);
      dispatch(setProfileInRedux(data as Profile));
    });
  }

  function handleDisabledStatusChange() {
    if (isUserDisabled) {
      setUserEnabled();
    } else {
      setUserDisabled();
    }
  }

  function setUserEnabled() {
    setUserEnabledApi(clientRecord.id, false);
    setIsUserDisabled(false);
  }

  function setUserDisabled() {
    setUserDisabledApi(clientRecord.id, true);
    setIsUserDisabled(true);
  }

  function fetchDesignProfile(clientEmail: undefined | string) {
    if (Object.entries(designProfile).length === 0) {
      firebase
        .firestore()
        .collection("newProfiles")
        .where("email", "==", clientEmail)
        .get()
        .then((resp) => {
          resp.forEach((doc) => setDesignProfile(doc.data()));
        });
    }
  }

  async function fetchProfile(userId: string) {
    await Profile.get(userId, true).then((clientRecord) => {
      // "true" makes a second request for secondary contacts

      setIsLoadingProfile(false);
      setClientRecord(clientRecord);
    });
    fetchDesignProfile(clientRecord.email);
  }

  async function subscribeToProject(userId: string) {
    const project = await Project.fetchWithProfileId(userId);
    const projectId = project.id;

    const docRef = firebase.firestore().collection("projects").doc(projectId);

    docRef.onSnapshot((snap) => {
      const data = snap.data();
      if (!data) return;
      data.id = snap.id;

      dispatch(setProjectInRedux(data as Project));
      setProject(data as Project);

      setIsVIP(ProjectFunctions.isVIP(data as Project));
      setIsInfluencer(data.tags?.includes("INFLUENCER"));
    });
  }

  async function fetchPostPLPHold(userId: string) {
    const project = await Project.fetchWithProfileId(userId);
    const projectId = project.id;

    if (!projectId) {
      return;
    }

    const querySnapshot = await firebase
      .firestore()
      .collection("postPLPHolds")
      .orderBy("createdAt", "desc")
      .where("archived", "==", false)
      .where("projectId", "==", projectId)
      .get();

    if (querySnapshot.empty) {
      return;
    }

    const doc = querySnapshot.docs[0];

    const data = doc.data();
    if (!data) return;

    setHoldStatuses(data["holdStatuses"] || []);
  }
}

export default withRouter(ClientDetail);
