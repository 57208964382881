import { Assignment, Project } from "@yardzen-inc/models";
import firebase from "firebase/compat";
import "firebase/compat/firestore";

export type AgentType = "modeling" | "design" | "wesley";

export const enum AgentTier {
  NewDesigner = "New Designer",
  Tier3 = "Tier 3",
  Tier2 = "Tier 2",
  Tier1 = "Tier 1",
  VIP = "VIP",
  Influencer = "Influencer",
}

type AgentTierOptions = {
  tier: AgentTier;
  availableQualifications: AgentQualificationSettings[];
};

type AgentQualificationSettings = {
  qualification: AgentQualification;
  isCheckedByDefault: boolean;
};

export const enum AgentQualification {
  Exterior = "EXTERIOR",
  Expedited = "EXPEDITED",
  VIP = "VIP",
  StarterExclusive = "STARTER EXCLUSIVE",
  StarterInclusive = "STARTER INCLUSIVE",
  CanShipProject = "CAN SHIP PROJECT",
  TwoDProDesigns = "2D PRO DESIGNS",
  ThreeDProDesigns = "3D PRO DESIGNS",
  Influencer = "INFLUENCER",
}

export const AGENT_TIERS: AgentTierOptions[] = [
  {
    tier: AgentTier.Influencer,
    availableQualifications: [
      {
        qualification: AgentQualification.Exterior,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Expedited,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.VIP,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterInclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterExclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.CanShipProject,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.TwoDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.ThreeDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Influencer,
        isCheckedByDefault: true,
      },
    ],
  },
  {
    tier: AgentTier.VIP,
    availableQualifications: [
      {
        qualification: AgentQualification.Exterior,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Expedited,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.VIP,
        isCheckedByDefault: true,
      },
      {
        qualification: AgentQualification.StarterInclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterExclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.CanShipProject,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.TwoDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.ThreeDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Influencer,
        isCheckedByDefault: false,
      },
    ],
  },
  {
    tier: AgentTier.Tier1,
    availableQualifications: [
      {
        qualification: AgentQualification.Exterior,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Expedited,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterInclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterExclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.CanShipProject,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.TwoDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.ThreeDProDesigns,
        isCheckedByDefault: false,
      },
    ],
  },
  {
    tier: AgentTier.Tier2,
    availableQualifications: [
      {
        qualification: AgentQualification.Exterior,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.Expedited,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterInclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterExclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.CanShipProject,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.TwoDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.ThreeDProDesigns,
        isCheckedByDefault: false,
      },
    ],
  },
  {
    tier: AgentTier.Tier3,
    availableQualifications: [
      {
        qualification: AgentQualification.Exterior,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterInclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.StarterExclusive,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.CanShipProject,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.TwoDProDesigns,
        isCheckedByDefault: false,
      },
      {
        qualification: AgentQualification.ThreeDProDesigns,
        isCheckedByDefault: false,
      },
    ],
  },

  { tier: AgentTier.NewDesigner, availableQualifications: [] },
];

export const AGENT_TYPES: AgentType[] = ["design", "modeling", "wesley"];

export const getAgentQualificationOptionsByTier = (
  agentTier: AgentTier
): AgentQualificationSettings[] => {
  const tierIndex = AGENT_TIERS.findIndex((t) => t.tier === agentTier);
  return AGENT_TIERS[tierIndex].availableQualifications;
};

export interface AgentProperties {
  userId: string;
  type: AgentType;
  firstName: string;
  lastName: string;
  email: string;
  paymentEmail?: string | null;
  slackEmail?: string | null;
  payRate: number;
  disabled: boolean;
  designerPodId?: string | null;
  groupId?: string | null;
  assignedAt: firebase.firestore.FieldValue | null;
  qualifications: AgentQualification[];
  slackMemberId?: string | null;
  tier?: AgentTier | null;
}

export declare class Agent implements AgentProperties {
  static create(props: Omit<AgentProperties, "assignedAt">): Promise<Agent>;
  static fetch(id: string): Promise<Agent>;
  static fetchWithUserId(userId: string): Promise<Agent>;
  static createFromDoc(
    doc:
      | firebase.firestore.DocumentReference
      | firebase.firestore.QueryDocumentSnapshot,
    listenToChanges?: boolean
  ): Promise<Agent>;
  static fetchAllOfType(
    type?: AgentType | null,
    limit?: number | null,
    startAt?: number | null,
    disabled?: boolean | null
  ): Promise<Agent[]>;
  static getUnpaidAgents(): Promise<{
    [key: string]: {
      agent: Agent;
      assignments: Assignment[];
    };
  }>;
  disabled: boolean;
  email: string;
  paymentEmail: string | null;
  slackEmail: string | null;
  firstName: string;
  id: string;
  lastName: string;
  onUpdate?: () => void;
  payRate: number;
  type: AgentType;
  userId: string;
  groupId: string | null;
  private _assignments;
  private _unsubs;
  assignedAt: firebase.firestore.FieldValue;
  designerPodId: string | null;
  qualifications: AgentQualification[];
  slackMemberId: string | null;
  tier: AgentTier | null;
  destroy(): void;
  disable(): Promise<firebase.functions.HttpsCallableResult>;
  enable(): Promise<firebase.functions.HttpsCallableResult>;
  getUnpaidAssignments(): Promise<Assignment[]>;
  getAssignments(
    includeCompleted?: boolean,
    forceFetch?: boolean
  ): Promise<Assignment[]>;
  setProperties(props: AgentProperties): Agent;
  save(): Promise<Agent>;
  getProperties(): AgentProperties;
  getAssignedDesignProjects(): Promise<Project[]>;
  getAssignedDesignProjectsQuery(): firebase.firestore.Query;
  protected _listen(): Promise<void>;
  private _getDocRef;
}
