import { Box, BoxProps, makeStyles, Tooltip } from "@material-ui/core";
import { Build, FlashOn, RoomServiceOutlined } from "@material-ui/icons";
import { VIPIcon } from "@yardzen-inc/react-common";
import * as React from "react";
import InfluencerIcon from "./InfluencerIcon";

interface ProjectTagIconDisplayProps extends BoxProps {
  isExpedited?: boolean;
  isPremium?: boolean;
  isVIP?: boolean;
  isProDesign?: boolean;
  isInfluencer?: boolean;
}

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "2rem",
    color: "#f58b2f",
  },
  proIcon: {
    fontSize: "2rem",
    color: "#9234eb",
  },
  flexbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
  },
  vipWrapper: {
    cursor: "default",
    display: "inline",
  },
}));

export const ProjectTagIconDisplay = ({
  isExpedited,
  isPremium,
  isVIP,
  isInfluencer,
  isProDesign,
  ...boxProps
}: ProjectTagIconDisplayProps) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.flexbox} ${boxProps.className}`} {...boxProps}>
      {isExpedited && (
        <Tooltip title="Client purchased an Expedited package">
          <FlashOn className={classes.icon} data-testid="expeditedIcon" />
        </Tooltip>
      )}
      {isPremium && (
        <Tooltip title="Client purchased a Premium package">
          <RoomServiceOutlined
            className={classes.icon}
            data-testid="premiumIcon"
          />
        </Tooltip>
      )}
      {isVIP && (
        <Tooltip title="Client is a VIP!" data-testid="VIPIcon">
          <Box className={classes.vipWrapper}>
            <VIPIcon size={35} />
          </Box>
        </Tooltip>
      )}
      {isInfluencer && (
        <Tooltip title="Client is a Influencer!" data-testid="InfluencerIcon">
          <Box className={classes.vipWrapper}>
            <InfluencerIcon size={35} />
          </Box>
        </Tooltip>
      )}
      {isProDesign && (
        <Tooltip title="Pro Design package">
          <Build className={classes.proIcon} data-testid="proDesignIcon" />
        </Tooltip>
      )}
    </Box>
  );
};
