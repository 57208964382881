import {
  AppBar,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Loyalty, PanTool } from "@material-ui/icons";
import Menu from "@material-ui/icons/Menu";
import { Agent, ProfileProperties } from "@yardzen-inc/models";
import { LotSize } from "@yardzen-inc/models/dist/models/Interfaces";
import clsx from "clsx";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { UpdateProjectDto } from "../api/pangaeaTypes";
import { pangaeaV1API } from "../api/pangaeaV1API";
import { DeactivationMessage } from "../Components/DeactivationMessage";
import DrawerListItem from "../Components/DrawerListItem";
import EditableChip from "../Components/EditableChip";
import { ProjectTagIconDisplay } from "../Components/ProjectTagIconDisplay";
import { WootricDisplay } from "../Components/Wootric/WootricDisplay";
import { HoldStatus, PackageType, Project } from "../Interfaces";
import { addPLPHoldStatus } from "../util/firebase/firebaseClient";
import { getPackageDisplayName } from "../util/functions/getPackageDisplayName";
import { makeProjectSlug } from "../util/makeProjectSlug";
import isOldBotanical from "./Revisions/util/isOldBotanical";

export const ROUTES: {
  label: string;
  value: string;
  limitEmployee: boolean;
}[] = [
  {
    label: "Detail",
    value: "detail",
    limitEmployee: false,
  },
  {
    label: "Assignments",
    value: "assignments",
    limitEmployee: false,
  },
  {
    label: "Onboard",
    value: "onboard",
    limitEmployee: false,
  },
  {
    label: "Budget Elements",
    value: "budget-checklist",
    limitEmployee: false,
  },
  {
    label: "Exterior Design",
    value: "exterior-design",
    limitEmployee: false,
  },
  {
    label: "CDM",
    value: "cdm",
    limitEmployee: true,
  },
  {
    label: "Feedback",
    value: "feedback",
    limitEmployee: false,
  },
  {
    label: "Quiz Answers",
    value: "typeform",
    limitEmployee: false,
  },
  {
    label: "Elements",
    value: "elements",
    limitEmployee: false,
  },
  {
    label: "Design Brief",
    value: "design-brief",
    limitEmployee: true,
  },
  {
    label: "Media",
    value: "media",
    limitEmployee: false,
  },
  {
    label: `Designs`,
    value: "deliverables",
    limitEmployee: false,
  },
  {
    label: `Revision Feedback`,
    value: "revision-feedback",
    limitEmployee: true,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  topLeftItemsContainer: {
    display: "flex",
    alignItems: "center",
  },
  topRightItemsContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "fit-content",
    marginLeft: "auto",
  },
  noShowMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  noShowUp: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  loyalty: {
    width: "35px",
    height: "35px",
  },
  panTool: (props: Props) => ({
    width: "35px",
    height: "35px",
    color: props.holdStatuses?.includes(HoldStatus.ManualHold)
      ? "#C05757"
      : undefined,
  }),
  backBtn: {
    marginLeft: "auto",
    width: "fit-content",
    height: "fit-content",
  },
  marginLeft: {
    marginLeft: "1rem",
  },
  iconDisplayContainer: {
    margin: "auto 1rem",
    display: "flex",
  },
  designerNameChip: {
    width: "100%",
    minWidth: "200px",
  },
  designerNameLoader: {
    marginTop: "6px",
  },
}));

interface ExtendedProfileProperties extends ProfileProperties {
  packageStyle?: string;
}

interface Props {
  clientRecord: ExtendedProfileProperties;
  clientProject: Project;
  isVIP: boolean;
  isInfluencer: boolean;
  isEmployee: boolean;
  holdStatuses: string[];
  isUserDisabled: boolean;
  setManualHoldModalOpen: Dispatch<SetStateAction<boolean>>;
  setTagManagerOpen: Dispatch<SetStateAction<boolean>>;
  refreshHoldStatus: () => Promise<void>;
}

function ClientDetailHeaderWithTabs(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(props);
  const match = useRouteMatch();

  const [showSidebar, setShowSidebar] = React.useState(false);
  const [assignedDesigner, setAssignedDesigner] = React.useState<Agent>();
  const [isFetchingAssignedDesignerData, setIsFetchingAssignedDesignerData] =
    React.useState(false);
  const [updateProject] = pangaeaV1API.useUpdateProjectMutation();
  const handleProjectUpdate = async (body: Partial<UpdateProjectDto>) => {
    await updateProject({
      id: props.clientProject.id,
      ...body,
    });
  };
  const activeTabValue = location.pathname.match(
    /client\/[^\/]+\/([^\/]+)/
  )?.[1];

  useEffect(() => {
    if (props.clientProject?.designerId) {
      async function fetchAssignedDesignerData() {
        try {
          setIsFetchingAssignedDesignerData(true);
          const agent = (await Agent.fetch(
            props.clientProject.designerId ?? ""
          )) as Agent;
          setAssignedDesigner(agent);
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetchingAssignedDesignerData(false);
        }
      }

      fetchAssignedDesignerData();
    }
  }, [props.clientProject?.designerId]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.topLeftItemsContainer}>
          <Typography
            style={{ marginLeft: "0.5rem" }}
            component="h1"
            variant="h5"
          >
            {props.clientRecord["firstName"]}&nbsp;
            {props.clientRecord["lastName"]}{" "}
            {
              // @ts-ignore
              props.clientRecord["isDesigner"] && "(Designer Account)"
            }
          </Typography>
          <ProjectTagIconDisplay
            className={classes.iconDisplayContainer}
            isExpedited={!!props.clientProject.isExpedited}
            isVIP={props.isVIP}
            isInfluencer={props.isInfluencer}
            isPremium={!!props.clientProject?.isPremium}
            isProDesign={!!props.clientProject?.isProDesign}
          />
          {!!props.clientRecord.package && props.clientRecord.package.length && (
            <>
              {props.isEmployee ? (
                <EditableChip
                  property="package"
                  collection="profiles"
                  rootDoc={props.clientRecord}
                  menuOptions={[
                    PackageType.Botanical,
                    PackageType.SmallSpace,
                    PackageType.FullYard,
                    PackageType.FrontYard,
                    PackageType.BackYard,
                    PackageType.CurbAppeal,
                    PackageType.OutdoorTransformation,
                    PackageType.UberPremium,
                    PackageType.EntireYardStarter,
                    PackageType.FrontOrBackyardStarter,
                  ]}
                  className={classes.marginLeft}
                  variant="outlined"
                  labelModificationFunction={(label: string) =>
                    displayPackageLabel(label, props.clientRecord)
                  }
                  onSave={async (label: string) => {
                    const updatedToPremium = label === PackageType.UberPremium;
                    if (updatedToPremium) {
                      await addPLPHoldStatus(
                        HoldStatus.ManualHold,
                        props.clientProject?.id
                      );

                      await handleProjectUpdate({
                        isPremium: true,
                      });
                      props.refreshHoldStatus();
                    }
                    if (!updatedToPremium && props.clientProject?.isPremium) {
                      await handleProjectUpdate({
                        isPremium: false,
                      });
                    }
                  }}
                />
              ) : (
                <Chip
                  className={classes.marginLeft}
                  color="default"
                  variant="outlined"
                  label={
                    props.clientRecord["packageStyle"] ??
                    props.clientRecord["package"]
                  }
                />
              )}
            </>
          )}
          <>
            {props.clientRecord.lotSize?.length &&
              (props.isEmployee ? (
                <EditableChip
                  property="lotSize"
                  collection="profiles"
                  rootDoc={props.clientRecord}
                  menuOptions={[
                    LotSize.Standard,
                    LotSize.Large,
                    LotSize.Acreage,
                  ]}
                  className={classes.marginLeft}
                  variant="outlined"
                  labelModificationFunction={(label: string) =>
                    label.includes("Lowes")
                      ? "MyLowe’s Rewards + Yardzen Design Package"
                      : label
                  }
                />
              ) : (
                <Chip
                  className={classes.marginLeft}
                  color="default"
                  variant="outlined"
                  label={props.clientRecord.lotSize}
                />
              ))}
          </>
          {
            // @ts-ignore
            !!props.clientRecord["additionalRevisions"] && (
              <Chip
                className={classes.marginLeft}
                variant="outlined"
                label={`${
                  // @ts-ignore
                  props.clientRecord["additionalRevisions"]
                } add’l revision${
                  // @ts-ignore
                  props.clientRecord["additionalRevisions"] > 1 ? "s" : ""
                }`}
              />
            )
          }
          {props.clientProject?.tags?.includes(
            "PURCHASED-ADDITIONAL-REVISION"
          ) && (
            <Chip
              className={classes.marginLeft}
              variant="outlined"
              label="Purchased Revision"
            />
          )}
          {props.clientProject?.tags?.includes(
            "COMPED-ADDITIONAL-REVISION"
          ) && (
            <Chip
              className={classes.marginLeft}
              variant="outlined"
              label="Comped Revision"
            />
          )}
          {props.clientProject.isHOA && (
            <Chip
              className={classes.marginLeft}
              variant="outlined"
              label="HOA (Extra Revision)"
            />
          )}
          <WootricDisplay
            className={classes.marginLeft}
            profileId={props.clientRecord.id}
          />
          <Tooltip title="Manage Project Tags">
            <IconButton
              className={classes.marginLeft}
              onClick={() => props.setTagManagerOpen(true)}
            >
              <Loyalty className={classes.loyalty} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Adjust Manual Hold. Placing a manual hold on a client's project means that they will not enter the designer self-assign queue. They will have to be manually assigned to a designer.">
            <IconButton
              className={classes.marginLeft}
              onClick={() => props.setManualHoldModalOpen(true)}
            >
              <PanTool className={classes.panTool} />
            </IconButton>
          </Tooltip>
          {props.clientRecord && (
            <>
              <Chip
                className={classes.marginLeft}
                variant="outlined"
                label={`Address: ${props.clientRecord?.street}, ${props.clientRecord?.city}, ${props.clientRecord?.state}`}
              />
              <Chip
                className={classes.marginLeft}
                variant="outlined"
                label={`Project Id: ${makeProjectSlug(
                  props.clientRecord.lastName ?? "",
                  props.clientRecord.street ?? ""
                )}`}
              />

              <Chip
                className={clsx(classes.designerNameChip, classes.marginLeft)}
                variant="outlined"
                label={
                  isFetchingAssignedDesignerData ? (
                    <CircularProgress
                      size={20}
                      className={classes.designerNameLoader}
                    />
                  ) : (
                    <span>
                      {assignedDesigner
                        ? `Designer: ${assignedDesigner?.firstName} ${assignedDesigner?.lastName}`
                        : "No designer assigned"}
                    </span>
                  )
                }
              />
            </>
          )}
        </div>

        <DeactivationMessage isUserDisabled={props.isUserDisabled} />
        <div className={classes.topRightItemsContainer}>
          <IconButton
            onClick={() => setShowSidebar(true)}
            className={`${classes.noShowUp} ${classes.backBtn}`}
          >
            <Menu></Menu>
          </IconButton>
          <IconButton
            onClick={() => {
              history.push("/client-table");
            }}
            className={classes.backBtn}
          >
            <ArrowBack />
          </IconButton>
        </div>
      </div>
      <Divider></Divider>
      <AppBar
        position="static"
        className={classes.noShowMobile}
        color="transparent"
      >
        <Tabs
          value={activeTabValue}
          onChange={handleTabChange}
          variant="scrollable"
          indicatorColor="primary"
        >
          {renderMenuItems("header")}
        </Tabs>
      </AppBar>
      <Drawer
        className={classes.noShowUp}
        variant="temporary"
        onClose={() => setShowSidebar(false)}
        open={showSidebar}
      >
        {renderMenuItems("sidebar")}
      </Drawer>
    </>
  );

  function renderMenuItems(menuType: "header" | "sidebar"): React.ReactNode[] {
    const tabs: React.ReactNode[] = [];

    for (let r of ROUTES) {
      if (r.limitEmployee && !props.isEmployee) continue;

      if (menuType === "header") {
        tabs.push(
          <Tab value={r.value} label={r.label} key={r.label + "TAB"} />
        );
      } else {
        tabs.push(
          <DrawerListItem
            key={r.label}
            selected={r.value === activeTabValue}
            onClick={() => {
              setShowSidebar(false);
              handleTabChange(null, r.value);
            }}
          >
            {r.label}
          </DrawerListItem>
        );
      }
    }

    return tabs;
  }

  function displayPackageLabel(
    pkg: string,
    clientRecord: ExtendedProfileProperties
  ) {
    if (
      pkg === PackageType.Botanical &&
      isOldBotanical(pkg, clientRecord.createdAt)
    ) {
      return "Old Botanical";
    }

    if (clientRecord.packageStyle) return clientRecord.packageStyle;

    return getPackageDisplayName(pkg);
  }

  function handleTabChange(e: any, value: string) {
    history.replace(`${match.url}/${value}`);
  }
}

export { ClientDetailHeaderWithTabs };
