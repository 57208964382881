import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Loyalty, Map, PanTool } from "@material-ui/icons";
import { Assignment, Project } from "@yardzen-inc/models";
import { VIPIcon } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import React, { FC, useEffect, useState } from "react";
import { ManageProjectTagsModal } from "../../ClientDetail/Detail/ManageProjectTagsModal";
import { ManualHoldToggleModal } from "../../ClientDetail/Detail/ManualHoldToggleModal";
import InfluencerIcon from "../../Components/InfluencerIcon";
import { HoldStatus } from "../../Interfaces";
import { PageComponentProps } from "../../SelfAssignPage/CurrentJobPageMap";
import mmddyyyy from "../../SelfAssignPage/mmddyyyy";
import getMapUrl from "../../util/googleMapSearch";
import useBudgetResponseText from "../../util/hooks/useBudgetResponseText";
import { usePostPLPHoldStatuses } from "../../util/hooks/usePostPLPHoldStatuses";

export interface WesleySummaryPageProjectInformationProps
  extends PageComponentProps {
  assignment: Assignment;
  addy: string | null;
}

const WesleySummaryPageProjectInformation: FC<WesleySummaryPageProjectInformationProps> =
  ({ addy, ...props }) => {
    const profile = props.profile;
    const project = props.project;
    const assignment = props.assignment;
    const designProfile = props.designProfile;

    const budgetResponseText = useBudgetResponseText(profile, project.id);

    const [tagsManagerOpen, setTagsManagerOpen] = useState(false);
    const [manualHoldModalOpen, setManualHoldModalOpen] = useState(false);

    const [updatedProject, setUpdatedProject] = useState<Project | null>(null);
    const { holds: holdStatuses, refresh: refreshHoldStatuses } =
      usePostPLPHoldStatuses(updatedProject?.id as string);

    useEffect(subscribeToProject, []);

    return (
      <>
        <ManageProjectTagsModal
          clientProject={updatedProject as Project}
          open={tagsManagerOpen}
          onClose={() => {
            refreshHoldStatuses();
            setTagsManagerOpen(false);
          }}
        />
        <ManualHoldToggleModal
          open={manualHoldModalOpen}
          onClose={() => {
            refreshHoldStatuses();
            setManualHoldModalOpen(false);
          }}
          project={updatedProject as Project}
          holdStatuses={holdStatuses || []}
        />
        <Box display="flex" alignItems="center" position="relative">
          {updatedProject && updatedProject.tags?.includes("VIP") && (
            <Tooltip title="Client is a VIP!">
              <VIPIcon size={35} />
            </Tooltip>
          )}
          {updatedProject && updatedProject.tags?.includes("INFLUENCER") && (
            <Tooltip title="Client is an INFLUENCER!">
              <InfluencerIcon size={35} />
            </Tooltip>
          )}
          <Tooltip title="Manage Project Tags">
            <IconButton
              style={{ marginLeft: "0.75rem" }}
              onClick={() => setTagsManagerOpen(true)}
            >
              <Loyalty
                style={{
                  width: "35px",
                  height: "35px",
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manage Manual Hold">
            <IconButton
              style={{ marginLeft: "0.75rem" }}
              onClick={() => setManualHoldModalOpen(true)}
            >
              <PanTool
                style={{
                  width: "35px",
                  height: "35px",
                  color: holdStatuses?.includes(HoldStatus.ManualHold)
                    ? "#C05757"
                    : undefined,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box p={1}>
          <Box display="flex">
            <Box flexBasis="100%">
              <Box>
                <Typography variant="caption">Job Title</Typography>
                <Typography variant="h5">{assignment.title}</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Client Name</Typography>
                <Typography variant="h5">{`${profile.firstName} ${profile.lastName}`}</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Address</Typography>
                <Typography variant="h5">{addy || "Unknown"}</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Job Description</Typography>
                <Typography variant="h5">{assignment.description}</Typography>
              </Box>
            </Box>
            <Box flexBasis="100%" ml={1}>
              <Box>
                <Typography variant="caption">Package</Typography>
                <Typography variant="h5">
                  {profile.package || "Unknown"}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Segment</Typography>
                <Typography variant="h5">
                  {(designProfile && designProfile.designingFor) || "Unknown"}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Budget</Typography>
                <Typography variant="h5">{budgetResponseText}</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="caption">Due Date</Typography>
                <Typography variant="h5">
                  {assignment.assignedAt &&
                    mmddyyyy(assignment.assignedAt + 1000 * 24 * 60 * 60 * 2)}
                </Typography>
              </Box>

              {addy && (
                <>
                  <Typography variant="caption">Google Maps</Typography>
                  <IconButton
                    href={getMapUrl(addy)}
                    target="_blank"
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    <Map></Map>
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );

    function subscribeToProject() {
      const docRef = firebase
        .firestore()
        .collection("projects")
        .doc(project.id);

      docRef.onSnapshot(async (snap) => {
        const data = snap.data();
        if (!data) return;

        data.id = snap.id;
        setUpdatedProject(data as Project);
      });
    }
  };

export { WesleySummaryPageProjectInformation };
